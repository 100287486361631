import React, { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import Navbar from "./Navbar";
import {
  getDocs,
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import { Navigate, useParams } from "react-router-dom";
import { RiCloseFill } from "react-icons/ri";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useAuthContext } from "../context/AuthContext";

const UserDetails = () => {
  const { user } = useAuthContext();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [FranchiseData, setFranchiseData] = useState([]);
  const [file, setFile] = useState("");
  const [email, setEmail] = useState("dmitbrainvita@gmail.com");
  const [per, setPerc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pdfLoading, setPDFLoading] = useState(false);
  const [rightNotes, setRightNotes] = useState({});
  const [leftNotes, setLeftNotes] = useState({});
  const [userEmail, setuserEmail] = useState("");
  const [userName, setuserName] = useState("");
  const [img, setImg] = useState("");
  const [popup, setPopUp] = useState(false);
  const params = useParams();

  const message = `Dear ${userName},

  Please find Enclose DMIT report.

  We thank you for your interest & confidence shown in our product.
  
  Hope we have satisfied to our best and your valuable comments on app store will boos our confidence.
  
  Our Other Services
  
  Personal Counselling
  Graphalogy
  Numerology
  
  Learn & Earn to Know your Inborn Talent & Hidden Potential
  (Online & Offline Classes) Daily or Weekend batches
  
  DMIT
  Graphalogy
  Numerology
  
  Happy to serve you as always
  
  Team
  
  Brain-Vita`;

  useEffect(() => {
    const fetchData = async () => {
      const RefForUser = doc(db, "users", params.id);
      const getUserData = await getDoc(RefForUser);
      setuserEmail(getUserData.data().Email);
      setuserName(getUserData.data()["Full Name"]);

      const RefForLeftHand = collection(
        db,
        "users",
        params.id,
        "LeftHandFingerprints"
      );

      const getData = await getDocs(RefForLeftHand);
      let list = [];
      getData.docs.forEach((elem) => {
        list.push(elem.data());
      });
      let sequencelist = [list[4], list[1], list[2], list[3], list[4]];
      setData(sequencelist);

      const RefForRightHand = collection(
        db,
        "users",
        params.id,
        "RightHandFingerprints"
      );
      const getData2 = await getDocs(RefForRightHand);
      let list2 = [];
      getData2.docs.forEach((elem) => {
        list2.push(elem.data());
      });
      let sequencelist2 = [list2[4], list2[1], list2[2], list2[3], list2[4]];
      setData2(sequencelist2);

      setLoading(false);
    };
    fetchData();
  }, []);

  const onRightNoteChange = (e) => {
    setRightNotes((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  // Add notes for right fingerprint
  const addRightNote = async (elem, finger) => {
    // Index
    const RefForRightHandIndex = doc(
      db,
      "users",
      params.id,
      "RightHandFingerprints",
      "Index"
    );
    await setDoc(RefForRightHandIndex, {
      ...elem,
      notes: {
        Center: rightNotes.RIndexCenter,
        Left: rightNotes.RIndexLeft,
        Right: rightNotes.RIndexRight,
      },
    });
    // Little
    const RefForRightHandLittle = doc(
      db,
      "users",
      params.id,
      "RightHandFingerprints",
      "Little"
    );
    await setDoc(RefForRightHandLittle, {
      ...elem,
      notes: {
        Center: rightNotes.RLittleCenter,
        Left: rightNotes.RLittleLeft,
        Right: rightNotes.RLittleRight,
      },
    });
    // Middle
    const RefForRightHandMiddle = doc(
      db,
      "users",
      params.id,
      "RightHandFingerprints",
      "Middle"
    );
    await setDoc(RefForRightHandMiddle, {
      ...elem,
      notes: {
        Center: rightNotes.RMiddleCenter,
        Left: rightNotes.RMiddleLeft,
        Right: rightNotes.RMiddleRight,
      },
    });
    // Ring
    const RefForRightHandRing = doc(
      db,
      "users",
      params.id,
      "RightHandFingerprints",
      "Ring"
    );
    await setDoc(RefForRightHandRing, {
      ...elem,
      notes: {
        Center: rightNotes.RRingCenter,
        Left: rightNotes.RRingLeft,
        Right: rightNotes.RRingRight,
      },
    });
    // Thumb
    const RefForRightHandThumb = doc(
      db,
      "users",
      params.id,
      "RightHandFingerprints",
      "Thumb"
    );
    await setDoc(RefForRightHandThumb, {
      ...elem,
      notes: {
        Center: rightNotes.RThumbCenter,
        Left: rightNotes.RThumbLeft,
        Right: rightNotes.RThumbRight,
      },
    });
  };

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPerc(progress);
          console.log(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const reff = doc(db, "users", params.id);
            updateDoc(reff, {
              report: downloadURL,
            });
            setPDFLoading(false);
          });
        }
      );
    };

    file && uploadFile();
  }, [file]);

  const onLeftNoteChange = (e) => {
    setLeftNotes((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  // Add notes for left fingerprint
  const addLeftNote = async (elem, finger) => {
    // Index
    const RefForLeftHandIndex = doc(
      db,
      "users",
      params.id,
      "LeftHandFingerprints",
      "Index"
    );
    await setDoc(RefForLeftHandIndex, {
      ...elem,
      notes: {
        Center: leftNotes.LIndexCenter,
        Left: leftNotes.LIndexLeft,
        Right: leftNotes.LIndexRight,
      },
    });
    // Little
    const RefForLeftHandLittle = doc(
      db,
      "users",
      params.id,
      "LeftHandFingerprints",
      "Little"
    );
    await setDoc(RefForLeftHandLittle, {
      ...elem,
      notes: {
        Center: leftNotes.LLittleCenter,
        Left: leftNotes.LLittleLeft,
        Right: leftNotes.LLittleRight,
      },
    });
    // Middle
    const RefForLeftHandMiddle = doc(
      db,
      "users",
      params.id,
      "LeftHandFingerprints",
      "Middle"
    );
    await setDoc(RefForLeftHandMiddle, {
      ...elem,
      notes: {
        Center: leftNotes.LMiddleCenter,
        Left: leftNotes.LMiddleLeft,
        Right: leftNotes.LMiddleRight,
      },
    });
    // Ring
    const RefForLeftHandRing = doc(
      db,
      "users",
      params.id,
      "LeftHandFingerprints",
      "Ring"
    );
    await setDoc(RefForLeftHandRing, {
      ...elem,
      notes: {
        Center: leftNotes.LRingCenter,
        Left: leftNotes.LRingLeft,
        Right: leftNotes.LRingRight,
      },
    });
    // Thumb
    const RefForLeftHandThumb = doc(
      db,
      "users",
      params.id,
      "LeftHandFingerprints",
      "Thumb"
    );
    await setDoc(RefForLeftHandThumb, {
      ...elem,
      notes: {
        Center: leftNotes.LThumbCenter,
        Left: leftNotes.LThumbLeft,
        Right: leftNotes.LThumbRight,
      },
    });
  };

  useEffect(() => {
    if (user?.email) {
      const reff = doc(db, "Franchises", user?.email);
      onSnapshot(reff, (elem) => {
        if (elem.data()) {
          setLoading(true);
          const refClients = collection(db, "users");
          onSnapshot(refClients, (clients) => {
            let list = [];
            clients.docs.forEach((client) => {
              list.push(client.data());
            });
            const filterList = list.filter((e) => {
              return (
                e.Franchise.replace(/\D/g, "").slice(-10) ===
                  elem.data().number.replace(/\D/g, "").slice(-10) &&
                e["Phone Number"] === params.id
              );
            });
            setEmail(elem.data().email);
            setFranchiseData(filterList);
          });
          setLoading(false);
        }
      });
    }
  }, []);

  if (!user) return <Navigate to="/login" />;

  return (
    <>
      <div>
        <Navbar />
        <div className="container py-2">
          <h1 className="mb-2 fw-bold mt-2">Right Hand Finger Prints</h1>
          {loading
            ? "Loading"
            : data2.map((elem, i) => {
                let finger = "";
                if (i === 0) {
                  finger = "Thumb";
                } else if (i === 1) {
                  finger = "Index";
                } else if (i === 2) {
                  finger = "Middle";
                } else if (i === 3) {
                  finger = "Ring";
                } else if (i === 4) {
                  finger = "Little";
                }

                return (
                  <div
                    className={`${finger !== "Thumb" && "mt-3"}`}
                    key={new Date() * Math.random()}
                  >
                    <h2>{finger}</h2>
                    <div className="d-flex justify-content-between mt-2">
                      <div className="d-flex flex-column">
                        <img
                          src={elem.Center?.image}
                          className="thumbnail"
                          onClick={() => {
                            setPopUp(true);
                            setImg(elem.Center?.image);
                          }}
                        />
                        {user.uid === process.env.REACT_APP_ADMIN && (
                          <input
                            type={"text"}
                            placeholder="Notes"
                            className="form-control w-75 mt-2"
                            id={`R${finger}Center`}
                            onChange={onRightNoteChange}
                          />
                        )}
                      </div>
                      <div>
                        <img
                          src={elem.Left?.image}
                          className="thumbnail"
                          onClick={() => {
                            setPopUp(true);
                            setImg(elem.Left?.image);
                          }}
                        />
                        {user.uid === process.env.REACT_APP_ADMIN && (
                          <input
                            type={"text"}
                            placeholder="Notes"
                            className="form-control w-75 mt-2 note"
                            id={`R${finger}Left`}
                            onChange={onRightNoteChange}
                          />
                        )}
                      </div>
                      <div>
                        <img
                          src={elem.Right?.image}
                          className="thumbnail"
                          onClick={() => {
                            setPopUp(true);
                            setImg(elem.Right?.image);
                          }}
                        />
                        {user.uid === process.env.REACT_APP_ADMIN && (
                          <input
                            type={"text"}
                            placeholder="Notes"
                            className="form-control w-75 mt-2 note"
                            id={`R${finger}Right`}
                            onChange={onRightNoteChange}
                          />
                        )}
                      </div>
                    </div>
                    {user.uid === process.env.REACT_APP_ADMIN && (
                      <button
                        className={`${
                          i === 4 ? "d-block" : "d-none"
                        } btn btn-primary mt-2`}
                        onClick={() => addRightNote(elem, finger)}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                );
              })}

          <h1 className="mb-2 mt-5 fw-bold">Left Hand Finger Prints</h1>
          {loading
            ? "Loading"
            : data.map((elem, i) => {
                let finger = "";
                if (i === 0) {
                  finger = "Thumb";
                } else if (i === 1) {
                  finger = "Index";
                } else if (i === 2) {
                  finger = "Middle";
                } else if (i === 3) {
                  finger = "Ring";
                } else if (i === 4) {
                  finger = "Little";
                }

                return (
                  <div
                    className={`${finger !== "Thumb" && "mt-3"}`}
                    key={new Date() * Math.random()}
                  >
                    <h2>{finger}</h2>
                    <div className="d-flex justify-content-between mt-2">
                      <div className="d-flex flex-column">
                        <img
                          src={elem.Center?.image}
                          className="thumbnail"
                          onClick={() => {
                            setPopUp(true);
                            setImg(elem.Center?.image);
                          }}
                        />
                        {user.uid === process.env.REACT_APP_ADMIN && (
                          <input
                            type={"text"}
                            placeholder="Notes"
                            className="form-control w-75 mt-2 note"
                            id={`L${finger}Center`}
                            onChange={onLeftNoteChange}
                          />
                        )}
                      </div>
                      <div>
                        <img
                          src={elem.Left?.image}
                          className="thumbnail"
                          onClick={() => {
                            setPopUp(true);
                            setImg(elem.Left?.image);
                          }}
                        />
                        {user.uid === process.env.REACT_APP_ADMIN && (
                          <input
                            type={"text"}
                            placeholder="Notes"
                            className="form-control w-75 mt-2 note"
                            id={`L${finger}Left`}
                            onChange={onLeftNoteChange}
                          />
                        )}
                      </div>
                      <div>
                        <img
                          src={elem.Right?.image}
                          className="thumbnail"
                          onClick={() => {
                            setPopUp(true);
                            setImg(elem.Right?.image);
                          }}
                        />
                        {user.uid === process.env.REACT_APP_ADMIN && (
                          <input
                            type={"text"}
                            placeholder="Notes"
                            className="form-control w-75 mt-2 note"
                            id={`L${finger}Right`}
                            onChange={onLeftNoteChange}
                          />
                        )}
                      </div>
                    </div>
                    {user.uid === process.env.REACT_APP_ADMIN && (
                      <button
                        className={`${
                          i === 4 ? "d-block" : "d-none"
                        } btn btn-primary mt-2`}
                        onClick={() => addLeftNote(elem, finger)}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                );
              })}
          {(user.uid === process.env.REACT_APP_ADMIN ||
            FranchiseData.length > 0) && (
            <>
              <hr className="mt-5" />
              <form
                action={`https://formsubmit.co/${email}`}
                method="POST"
                encType="multipart/form-data"
              >
                <h3 className="fw-bold">Send Email</h3>
                <input type="hidden" name="_cc" value={userEmail} />
                <input type="hidden" name="name" value={"brain-vita.com"} />
                <input type="hidden" name="message" value={message} />
                <input type="hidden" name="_captcha" value="false"></input>
                <input
                  type="file"
                  id="file"
                  name="attachment"
                  onChange={(e) => setFile(e.target.files[0])}
                  className="form-control w-75 mt-2"
                />
                <button
                  type="submit"
                  className="btn btn-primary mt-2"
                  disabled={per < 100 ? true : false}
                >
                  {pdfLoading ? "loading" : "Send"}
                </button>
              </form>
            </>
          )}
        </div>
      </div>
      {popup && (
        <div className="PopUp-bg">
          <RiCloseFill
            className="close"
            onClick={() => setPopUp(false)}
            color="white"
            size={35}
          />
          <div className="PopUp">
            <div
              style={{ background: `url(${img})` }}
              alt="img"
              className="img"
            ></div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetails;
