import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { auth, db } from "../firebase";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import Navbar from "./Navbar";
import { signInWithEmailAndPassword } from "firebase/auth";

const StaffList = () => {
  const { user } = useAuthContext();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const reff = collection(db, "staff");

    onSnapshot(reff, (elem) => {
      setLoading(true);
      let list = [];
      elem.docs.forEach((user) => {
        list.push(user.data());
      });
      setData(list);
      setLoading(false);
    });
  }, []);

  const HandleDelete = async (elem) => {
    const text = "Are you sure?";

    if (window.confirm(text) == true) {
      const password = elem.password;
      if (password.length >= 6) {
        const res = await signInWithEmailAndPassword(
          auth,
          elem.email,
          password
        );
        await deleteDoc(doc(db, "staff", elem.email));
        await res.user.delete();
      } else {
        await deleteDoc(doc(db, "staff", elem.email));
      }
    }
  };

  if (user?.uid !== process.env.REACT_APP_ADMIN)
    return <Navigate to="/login" />;

  return (
    <div>
      <Navbar />
      <section className="TableList UsersList mt-5">
        <p className="p-2 mb-2 fw-bold">All Registered Franchises</p>
        <Table responsive>
          <thead>
            <tr>
              <th>Sno. #</th>
              <th>Name</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          {loading && (
            <p
              style={{
                position: "absolute",
                zIndex: 2000,
                left: "47%",
                fontSize: "1.1rem",
                marginTop: "10px",
              }}
            >
              Loading
            </p>
          )}
          <tbody>
            {data.map((element, i) => {
              return (
                <tr key={element.email}>
                  <td>{i + 1}.</td>
                  <td>{element.name}</td>
                  <td>{element.email}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => HandleDelete(element)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default StaffList;
