import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import UsersList from "../components/usersList";
import { useAuthContext } from "../context/AuthContext";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Box from "../components/Box";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { FaUser, FaUserCheck } from "react-icons/fa";
import { RiUserUnfollowFill } from "react-icons/ri";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { MdEdit } from "react-icons/md";

const Home = () => {
  const [users, setUsers] = useState([]);
  const [Rusers, setRUsers] = useState([]);
  const [NRusers, setNRUsers] = useState([]);
  const [searchParams] = useSearchParams();
  const [Franchise, setFranchise] = useState(null);
  const [Franchises, setFranchises] = useState([]);
  const [PFranchises, setPFranchises] = useState([]);
  const [images, setImages] = useState([]);

  const { user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      user !== null &&
      (user?.uid === process.env.REACT_APP_ADMIN || user?.type === "staff")
    ) {
      // console.log(true);
      const reff = collection(db, "users");

      // console.log(userdat?.phoneNumber);

      onSnapshot(reff, (elem) => {
        let list = [];
        let list2 = [];
        elem.docs.forEach((elem) => {
          list.push(elem.data());
        });
        setUsers(list);
        setImages(list2);

        const ReportUsers = list.filter((elem) => {
          return elem.report;
        });

        list.filter((elem) => {
          return elem.report;
        });

        const NoReportUsers = list.filter((elem) => {
          return !elem.report;
        });
        setRUsers(ReportUsers);
        setNRUsers(NoReportUsers);
      });

      // Franchises
      const franchiseRef = collection(db, "Franchises");
      onSnapshot(franchiseRef, (elem) => {
        let list = [];
        elem.docs.forEach((user) => {
          list.push(user.data());
        });
        setFranchises(list);
      });

      const requestRef = collection(db, "franchise-request");
      onSnapshot(requestRef, (elem) => {
        let list = [];
        elem.docs.forEach((user) => {
          list.push(user.data());
        });
        setPFranchises(list);
      });
    } else {
      if (user !== null && user?.phoneNumber) {
        const reff = doc(db, "users", user?.phoneNumber);
        onSnapshot(reff, (elem) => {
          setUsers(elem.data());
        });
      } else if (user?.email) {
        const Freff = doc(db, "Franchises", user?.email);
        onSnapshot(Freff, (elem) => {
          setFranchise(elem.data());
        });
      }
    }
  }, []);

  const handleEdit = async () => {
    const promptt = prompt("Edit Your Franchise Code!");
    if (promptt) {
      const reff = doc(db, "users", user?.phoneNumber);
      updateDoc(reff, {
        Franchise: promptt,
      });
    }
  };


  if (!user) return <Navigate to="/login" />;

  return (
    <>
      <Navbar />
      {(user?.uid === process.env.REACT_APP_ADMIN || user.type === "staff") && (
        <>
          <div className="Cards mt-5">
            <Box
              bg="green"
              user={users.length}
              userType={"All Users"}
              userImg={<FaUser size={70} />}
            />
            <Box
              bg="blue"
              user={Rusers.length}
              userType={"Report Users"}
              userImg={<FaUserCheck size={70} />}
            />
            <Box
              bg="orange"
              user={NRusers.length}
              userType={"No Report Users"}
              userImg={<RiUserUnfollowFill size={70} />}
            />
            <Box
              bg="darkblue"
              user={Franchises.length}
              userType={"Total Franchises"}
              userImg={<FaUser size={70} />}
            />
            <Box
              bg="darkorange"
              user={PFranchises.length}
              userType={"Pending Franchises"}
              userImg={<RiUserUnfollowFill size={70} />}
            />
            <Box
              bg="bg-dark"
              user={Franchises.length}
              userType={"Active Franchises"}
              userImg={<FaUserCheck size={70} />}
            />
          </div>
          <UsersList />
        </>
      )}{" "}
      {Franchise && (
        <div className="userContainer w-50 mx-auto mt-5">
          <div className="d-flex align-items-center ">
            <img
              src="https://avatars.githubusercontent.com/u/7805715?v=4"
              className="userImg"
            />
            <h1 className="ms-3">{Franchise?.name}</h1>
          </div>
          <div className="d-flex justify-content-between  align-items-center mt-3">
            <p className="fs-7">Gender</p>
            <p className="fs-7">{Franchise?.gender}</p>
          </div>
          <hr />
          <div className="d-flex justify-content-between  align-items-center mt-3">
            <p className="fs-7">Email</p>
            <p className="fs-7">{Franchise?.email}</p>
          </div>
          <hr />
          <div className="d-flex justify-content-between  align-items-center mt-3">
            <p className="fs-7">Phone Number</p>
            <p className="fs-7">{Franchise?.number}</p>
          </div>
          <hr />
          <div className="d-flex justify-content-between  align-items-center mt-3">
            <p className="fs-7">Code</p>
            <p className="fs-7">
              {Franchise.number?.replace(/\D/g, "").slice(-10)}
            </p>
          </div>
          <hr />
          <div className="mt-3">
            <p className="fs-7 fw-bold">Services:</p>
            {Franchise.service?.map((elem) => {
              return (
                <div key={elem}>
                  <hr />
                  <div className="d-flex justify-content-between w-100 mt-1">
                    <p className="fs-7">{elem}</p>
                    <BsFillPatchCheckFill size={24} color="blue" />
                  </div>
                </div>
              );
            })}
          </div>
          <hr />
          <div className="d-flex justify-content-between  align-items-center mt-1">
            <p className="fs-7">Show Clients</p>
            <button
              className="btn btn-primary px-2 py-1"
              onClick={() => {
                navigate(
                  `/clients?code=${Franchise.number
                    ?.replace(/\D/g, "")
                    .slice(-10)}`
                );
              }}
            >
              View
            </button>
          </div>
        </div>
      )}
      {!Franchise && user?.phoneNumber && (
        <div className="userContainer w-50 mx-auto mt-5">
          <div className="d-flex align-items-center ">
            <img
              src="https://avatars.githubusercontent.com/u/7805715?v=4"
              className="userImg"
            />
            <h1 className="ms-3">{users["Full Name"]}</h1>
          </div>
          <div className="d-flex justify-content-between  align-items-center mt-3">
            <p className="fs-7">Email</p>
            <p className="fs-7">{users["Email"]}</p>
          </div>
          <hr />
          <div className="d-flex justify-content-between  align-items-center mt-2">
            <p className="fs-7">Franchise</p>
            <div className="d-flex">
              <p className="fs-7">{users["Franchise"]}</p>
              <MdEdit
                size={22}
                className="ms-2"
                color="blue"
                cursor={"pointer"}
                onClick={handleEdit}
              />
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between  align-items-center mt-2">
            <p className="fs-7">Father's Name</p>
            <p className="fs-7">{users["Father's Name"]}</p>
          </div>
          <hr />
          <div className="d-flex justify-content-between  align-items-center mt-2">
            <p className="fs-7">Mother's Name</p>
            <p className="fs-7">{users["Mother's Name"]}</p>
          </div>
          <hr />
          <div className="d-flex justify-content-between  align-items-center mt-2">
            <p className="fs-7">Date of birth</p>
            <p className="fs-7">{users["Date of Birth"]}</p>
          </div>
          <hr />
          <div className="d-flex justify-content-between  align-items-center mt-1">
            <p className="fs-7">Place of birth</p>
            <p className="fs-7">{users["Place of Birth"]}</p>
          </div>
          <hr />
          <div className="d-flex justify-content-between  align-items-center mt-1">
            <p className="fs-7">Education</p>
            <p className="fs-7">{users["Education"]}</p>
          </div>
          <hr />
          <div className="d-flex justify-content-between  align-items-center mt-1">
            <p className="fs-7">Member since</p>
            <p className="fs-7">{users["Registered On"]}</p>
          </div>
          <hr />
          <div className="d-flex justify-content-between  align-items-center mt-1">
            <p className="fs-7">Report</p>
            <p className="fs-7">
              {users["report"] ? (
                <a
                  href={users["report"]}
                  className="btn btn-primary"
                  target={"_blank"}
                  download
                >
                  Download
                </a>
              ) : (
                "No report"
              )}
            </p>
          </div>
          <hr />
          <div className="d-flex justify-content-between  align-items-center mt-1">
            <p className="fs-7">Finger Prints</p>
            <button
              className="btn btn-primary px-2 py-1"
              onClick={() => {
                navigate(`/userdetails/${users["Phone Number"]}`);
              }}
            >
              View
            </button>
          </div>
        </div>
      )}
      {/* <Footer /> */}
    </>
  );
};

export default Home;
