import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { auth, db } from "../firebase";
import logo from "../Logo n.jpeg";
import { AiFillChrome } from "react-icons/ai";
import { signInWithEmailAndPassword, updatePassword } from "firebase/auth";

const Navbar = () => {
  const { dispatch, user } = useAuthContext();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const logOut = async () => {
    try {
      dispatch({ type: "LOGOUT", payload: null });
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  const HandleChangePassword = async () => {
    const previousPassword = prompt("Old password:");
    try {
      const res = await signInWithEmailAndPassword(
        auth,
        user?.email,
        previousPassword
      );
      const newPassword = prompt("New password:");
      if (newPassword) {
        await updatePassword(res.user, newPassword);
        alert("Successfully Changed!");
      }
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    if (user?.email) {
      setLoading(true);
      const reff = doc(db, "Franchises", user?.email);
      onSnapshot(reff, (elem) => {
        setData(elem.data());
        setLoading(false);
      });
    }
  }, []);

  return (
    <nav className="navbar bg-dark navbar-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src={logo} style={{ width: "45px", borderRadius: "2px" }} />
        </a>
        {(user?.uid === process.env.REACT_APP_ADMIN ||
        user.type === "staff") && (
          <>
            <ul className="d-flex ms-3 me-auto mb-2 mb-lg-0 ps-0">
              <li className="nav-item">
                <Link to="/franchise-request" className="nav-link text-light">
                  Franchise requests
                </Link>
              </li>
              <li className="nav-item ms-3">
                <Link to="/franchises" className="nav-link text-light">
                  Franchises
                </Link>
              </li>
            {user.type !== "staff" && 
            <> 
              <li className="nav-item ms-3">
                <Link to="/addstaff" className="nav-link text-light">
                  Add Staff
                </Link>
              </li>
              <li className="nav-item ms-3">
                <Link to="/staffList" className="nav-link text-light">
                  Staff
                </Link>
              </li>
            <li
                className="nav-item text-light ms-3 "
                onClick={HandleChangePassword}
                style={{ listStyle: "none" }}
              >
                <p type="button">Change Password</p>
              </li>
              </>}
            </ul>
          </>
        )}
        {data && <h1 className="text-light">Franchise</h1>}
        {!loading && !data && user?.uid !== process.env.REACT_APP_ADMIN && user.type !== "staff" && (
          <h1 className="text-light">Client</h1>
        )}
         {!loading && !data && user?.uid !== process.env.REACT_APP_ADMIN && user.type === "staff" && (
          <h1 className="text-light me-auto">Staff</h1>
        )}
        <div>
          <a
            href="https://brain-vita.com"
            className="me-2 text-warning"
            target={"_blank"}
            role={"button"}
          >
            <AiFillChrome size={30} />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.brainvita.dmit"
            className="btn btn-primary me-2"
            target={"_blank"}
            role={"button"}
          >
            Download App
          </a>
          <button className="btn btn-danger" onClick={logOut}>
            Log out
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
