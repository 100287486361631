import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { db } from "../firebase";
import { useAuthContext } from "../context/AuthContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../Logo n.jpeg";

const StaffLogin = () => {
  const { LogIn, dispatch } = useAuthContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const Login = async (e) => {
    e.preventDefault();
    const res = await LogIn(email, password);
    const reff = doc(db, "staff", email);
    const getData = await getDoc(reff);
    const user = {
      email: res.user.auth.currentUser.email,
      uid: res.user.uid,
      type: getData.data().type
    };
    console.log(user);
    dispatch({ type: "LOGIN", payload: user });
    let objectString = JSON.stringify(user);
    console.log(objectString);
    document.cookie = "user=" + objectString;
    navigate("/");
  };

  return (
    <>
    <nav className="loginNav mx-auto d-flex justify-content-between 
    align-items-center" style={{width: "90%"}} >
      <a href="/">
        <img src={logo} style={{ width: "50px", borderRadius: "2px" }} />
      </a>
      <div>
        <a href="/admin">Admin</a>
        <a href="/staff" className="ms-2">
          Staff
        </a>
        <a href="/franchise-login" className="ms-2">
          Franchise
        </a>
      </div>
    </nav>
    <div className="w-75 mt-3 mx-auto">
      <h1>Login As Staff</h1>
      <form className="mt-3">
        <div className="mb-3">
          <label for="exampleInputEmail1" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label for="exampleInputPassword1" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary" onClick={Login}>
          Submit
        </button>
      </form>
    </div>
    </>
  );
};

export default StaffLogin;
