import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import Navbar  from "./Navbar"
import { auth, db } from "../firebase";

const Staffform = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const Add = async (e) => {
    e.preventDefault();
      try {
        const reff = doc(db, "staff", email);
        const res = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        )
        await setDoc(reff, {
          email: email,
          password: password,
          type: "staff",
          name: name
        });
        alert("Your request has been submitted!");
        // navigate("/");
      } catch (err) {
        alert("All fields are required!");
        console.log(err);
      }
  };

  return (
    <>
      <Navbar />
      <div className="w-75 mt-3 mx-auto">
        <h1>Add Staff</h1>
        <form className="mt-3">
        <div className="mb-3">
          <label for="exampleInputEmail1" className="form-label">
            Full Name 
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
          <div className="mb-3">
            <label for="exampleInputEmail1" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label for="exampleInputPassword1" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary" onClick={Add}>
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default Staffform;
