import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { Alert } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { db } from "../firebase";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import logo from "../Logo n.jpeg";

const FranchiseRequest = () => {
  const { user } = useAuthContext();
  const [franchise, setFranchise] = useState({});
  const [service, setService] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [message, setMessage] = useState("");
  const [value, setValue] = useState("");
  const [gender, setGender] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFranchise((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleChangeService = (e) => {
    if (e.target.checked) {
      setService((prev) => [...prev, e.target.id]);
    } else {
      setService(
        service.filter((elem) => {
          return elem !== e.target.id;
        })
      );
    }
  };

  const requestFranchise = async () => {
    if (numbers.includes(value)) {
      alert("Already used number!");
    } else if (
      emails.includes(franchise.email) ||
      franchise.email === "veersec.com@gmail.com"
    ) {
      alert("Already used email!");
    } else if (
      !numbers.includes(value) &&
      Object.keys(franchise).length === 4 &&
      gender.length > 0 &&
      value.length >= 12
    ) {
      const reff = doc(db, "franchise-request", franchise.email);
      const month = new Date().toLocaleDateString().split("/")[0] - 1;
      const year = Number(new Date().toLocaleDateString().split("/")[2]) + 1;
      const date = `31-${month}-${year}`;


      setDoc(reff, {
        ...franchise,
        number: value,
        membership: date,
        service: service,
        gender: gender,
        password: franchise.password,
        type: "franchise",
      });
      setMessage("Your request has been submitted!");
      navigate("/")
    } else {
      alert("All fields are required!");
    }
  };

  useEffect(() => {
    const reff = collection(db, "Franchises");
    onSnapshot(reff, (elem) => {
      let list = [];
      let list2 = [];
      elem.docs.forEach((e) => {
        list.push(e.data()[0]?.number);
        list2.push(e.data()[0]?.email);
      });
      setNumbers(list);
      setEmails(list2);
    });
  }, []);

  if (user) return <Navigate to={"/"} />;

  return (
    <section
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <nav className="loginNav">
        <a href="/">
          <img src={logo} style={{ width: "50px", borderRadius: "2px" }} />
        </a>
      </nav>
      {message.length > 0 && (
        <Alert
          className="alert-primary"
          style={{ position: "absolute", top: "0", width: "100%" }}
        >
          {message}
        </Alert>
      )}
      <div className="login-form w-50">
        <h1 className="fw-bold text-center">Send Request!</h1>
        <div className="mt-3">
          <label className="fw-bold">Name</label>
          <input
            type={"text"}
            placeholder="Full Name"
            className="form-control py-1"
            value={franchise.name}
            id="name"
            onChange={(e) => handleChange(e)}
          />
          <label className="fw-bold mt-1">Email</label>
          <input
            type={"email"}
            placeholder="Email"
            className="form-control py-1"
            id="email"
            value={franchise.email}
            onChange={(e) => handleChange(e)}
          />
          <label className="fw-bold mt-1">Date of Birth</label>
          <input
            type={"date"}
            placeholder="Date of Birth"
            className="form-control py-1"
            id="dob"
            value={franchise.dob}
            onChange={(e) => handleChange(e)}
          />
          <label className="fw-bold mt-1">Gender</label>
          <div className="d-flex">
            <input
              type="radio"
              name="type"
              value="Male"
              checked={gender === "Male"}
              onChange={(e) => setGender(e.target.value)}
            />
            <label htmlFor="radio-income" className="ms-2">
              Male
            </label>
            <input
              type="radio"
              name="type"
              value="Female"
              checked={gender === "Female"}
              className="ms-2"
              onChange={(e) => setGender(e.target.value)}
            />
            <label htmlFor="radio-expenses" className="ms-2">
              Female
            </label>
          </div>
          <label className="fw-bold mt-1">Phone Number</label>
          <PhoneInput
            placeholder="Enter phone number"
            value={value}
            onChange={setValue}
            className="form-control"
          />
          <label className="fw-bold mt-1">Code:</label>
          <p className="fw-bold">{value?.replace(/\D/g, "").slice(-10)} </p>
        
          <label className="fw-bold mt-1">Password</label>
          <input
            type={"password"}
            placeholder="Password"
            className="form-control py-1"
            id="password"
            value={franchise.password}
            onChange={(e) => handleChange(e)}
          />
            <div className="form-check mt-1">
            <input
              className="form-check-input"
              type="checkbox"
              id=" DMIT"
              onChange={(e) => handleChangeService(e)}
            />
            <label className="form-check-label" htmlFor="DMIT">
              DMIT
            </label>
          </div>

          <div className="form-check mt-1">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="Numerology"
              onChange={(e) => handleChangeService(e)}
            />
            <label className="form-check-label" htmlFor="Numerology">
              Numerology
            </label>
          </div>
          <div className="form-check mt-1">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="Graphology"
              onChange={(e) => handleChangeService(e)}
            />
            <label className="form-check-label" htmlFor="Graphology">
              Graphology
            </label>
          </div>
          <div className="form-check mt-1">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="Crystals"
              onChange={(e) => handleChangeService(e)}
            />
            <label className="form-check-label" htmlFor="Crystals">
              Crystals
            </label>
          </div>
          <div className="form-check mt-1">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="Others"
              onChange={(e) => handleChangeService(e)}
            />
            <label className="form-check-label" htmlFor="Others">
              Others
            </label>
          </div>
          <button
            className="btn btn-primary mt-1"
            onClick={() => requestFranchise()}
          >
            Request
          </button>

          <p className="text-center mt-1">
            Already a Franchise? <Link to="/franchise-login">Login here!</Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default FranchiseRequest;
