import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { auth, db } from "../firebase";
import banner from "../Punch Line Brain Vita.jpg";
import logo from "../Logo n.jpeg";

const ClientLogin = () => {
  const [number, setNumber] = useState("");
  const [expand, setExpand] = useState(false);
  const [error, setError] = useState("");
  const { user, dispatch } = useAuthContext();
  const navigate = useNavigate();

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {},
      },
      auth
    );
  };

  const requestOTP = async () => {
    if (number.length >= 12) {
      const userExist = (await getDoc(doc(db, "users", number))).exists();
      if (userExist) {
        setExpand(true);
        generateRecaptcha();
        let appverifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, number, appverifier)
          .then((confirmation) => {
            window.confirmationResult = confirmation;
            setError("");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setError(`User doesnt exist!`);
      }
    }
  };

  const verifyOTP = (OTP) => {
    if (OTP.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(OTP)
        .then((result) => {
          dispatch({ type: "LOGIN", payload: result.user });
          let objectString = JSON.stringify(result.user);
          document.cookie = "user=" + objectString + "" + "; path=/";
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
          setError("Something went wrong! Check your internet.");
        });
    }
  };

  if (user) return <Navigate to="/" />;

  return (
    <section
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <nav className="loginNav mx-auto d-flex justify-content-between 
      align-items-center" style={{width: "90%"}} >
        <a href="/">
          <img src={logo} style={{ width: "50px", borderRadius: "2px" }} />
        </a>
        <div>
          <a href="/admin">Admin</a>
          <a href="/staff" className="ms-2">
            Staff
          </a>
          <a href="/franchise-login" className="ms-2">
            Franchise
          </a>
        </div>
      </nav>
      {error.length > 0 && (
        <Alert
          className="alert-danger"
          style={{ position: "absolute", top: "0", width: "100%" }}
        >
          User does't exist!{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.brainvita.dmit"
            target={"_blank"}
          >
            Download our app
          </a>{" "}
          to register!
        </Alert>
      )}
      <div className="login-form w-50">
        <h1 className="fw-bold text-center">Welcome Back!</h1>
        <div className="mt-3">
          <label className="fw-bold">
            Phone Number{" "}
            <span className="text-muted fw-light">
              (start with country code e.g +91)
            </span>
          </label>
          <input
            type={"text"}
            placeholder="Phone Number"
            className="form-control"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
          {expand && (
            <div className="mt-2">
              <label className="fw-bold">OTP</label>
              <input
                type={"number"}
                placeholder="OTP"
                className="form-control"
                onChange={(e) => verifyOTP(e.target.value)}
              />
            </div>
          )}
          {!expand && (
            <button className="btn btn-primary mt-2" onClick={requestOTP}>
              Request OTP
            </button>
          )}
          <p className="text-center mt-2">
            or Login as <Link to="/franchise">Franchise!</Link>
          </p>
          <div id="recaptcha-container"></div>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <a
            href="https://play.google.com/store/apps/details?id=com.brainvita.dmit"
            target={"_blank"}
            className="btn btn-warning"
          >
            Download App Now!
          </a>
        </div>
      </div>
    </section>
  );
};

export default ClientLogin;
