import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useAuthContext } from "../context/AuthContext";
import { auth, db } from "../firebase";

const FRequests = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  // const [userEmail, setuserEmail] = useState("");
  const userEmail = useRef();
  const [email, setEmail] = useState("dmitbrainvita@gmail.com");
  const { user } = useAuthContext();
  const btn = useRef();

  useEffect(() => {
    const reff = collection(db, "franchise-request");

    onSnapshot(reff, (elem) => {
      setLoading(true);
      let list = [];
      elem.docs.forEach((user) => {
        list.push(user.data());
      });
      setData(list);
      setLoading(false);
    });
  }, []);

  const handleAccept = async (elem) => {
    setMessage(`Dear ${elem.name},
    Your request to be a franchise is accepted by brain-vita.com,
    Your password is: ${elem?.password}`);
    userEmail.current = elem.email;
    const res = await createUserWithEmailAndPassword(
      auth,
      elem?.email,
      elem?.password
    );
    await setDoc(doc(db, "Franchises", res.user?.email), {
      ...elem,
      timeStamp: serverTimestamp(),
    });
    await deleteDoc(doc(db, "franchise-request", res.user?.email));
    btn.current.click();
  };

  if (user?.uid !== process.env.REACT_APP_ADMIN && user?.type !== "staff") return <Navigate to="/login" />;

  return (
    <>
    <div>
      <Navbar />
      <section className="TableList UsersList mt-5">
        <p className="p-2 mb-2 fw-bold">All Franchise Requests</p>
        <Table responsive>
          <thead>
            <tr>
              <th>Sno. #</th>
              <th>Full name</th>
              <th>Phone number</th>
              <th>Email</th>
              <th>Code</th>
              <th>Date of birth</th>
              <th>Action</th>
            </tr>
          </thead>
          {loading && (
            <p
              style={{
                position: "absolute",
                zIndex: 2000,
                left: "47%",
                fontSize: "1.1rem",
                marginTop: "10px",
              }}
            >
              Loading
            </p>
          )}
          <tbody>
            {data.map((element, i) => {
              return (
                <tr id={element.email}>
                  <td>{i + 1}.</td>
                  <td>{element.name}</td>
                  <td>{element.number}</td>
                  <td>{element.email}</td>
                  <td>{element.number}</td>
                  <td>{element.dob}</td>
                 {user?.type !== "staff" && <th>
                    <button
                      className="btn btn-primary mt-1 px-2 py-1"
                      onClick={() => handleAccept(element)}
                    >
                      Accept
                    </button>
                  </th>
            }
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>
      <form
        action={`https://formsubmit.co/${email}`}
        method="POST"
        style={{ display: "none" }}
      >
        <input type="email" name="_cc" value={userEmail.current} />
        <input type="hidden" name="name" value={"brain-vita.com"} />
        <input type="hidden" name="message" value={message} />
        <input type="hidden" name="_captcha" value="false"></input>
        <button type="submit" className="btn btn-primary mt-2" ref={btn}>
          Send
        </button>
      </form>
    </div>
    {/* <Footer /> */}
    </>
  );
};

export default FRequests;
