import { signInWithEmailAndPassword } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useAuthContext } from "../context/AuthContext";
import { auth, db } from "../firebase";
import { updatePassword } from "firebase/auth";
import Footer from "../components/Footer";

const Franchises = () => {
  const { user } = useAuthContext();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const reff = collection(db, "Franchises");

    onSnapshot(reff, (elem) => {
      setLoading(true);
      let list = [];
      elem.docs.forEach((user) => {
        list.push(user.data());
      });
      setData(list);
      setLoading(false);
    });
  }, []);

  const DeleteFranchise = async (elem) => {
    const text = "Are you sure?";

    if (window.confirm(text) == true) {
      const password = elem.password;
      const res = await signInWithEmailAndPassword(auth, elem?.email, password);
      await deleteDoc(doc(db, "Franchises", res.user?.email));
      await res.user.delete();
    }
  };

  // const RenewFranchise = async (elem) => {
  //   const membership = elem.membership;
  //   const currentData = new Date().toLocaleDateString();
  //   const month = Number(currentData.split("/")[0]) - 1;
  //   const year = Number(currentData.split("/")[2]) + 1;
  //   const date = `31-${month}-${year}`;

  //   if (
  //     currentData.split("/")[0] >= Number(membership.split("-")[1]) &&
  //     currentData.split("/")[2] === membership.split("-")[2]
  //   ) {
  //     const reff = doc(db, "Franchises", elem?.email);
  //     await updateDoc(reff, {
  //       membership: date,
  //     });
  //   } else {
  //     alert("Renewal not required!");
  //   }
  // };

  const EditPassword = async (elem) => {
    console.log(elem);
    const password = elem.password;
    const res = await signInWithEmailAndPassword(auth, elem?.email, password);
    const promptt = prompt("New password:");
    if (promptt) {
      await updatePassword(res.user, promptt);
      await updateDoc(doc(db, "Franchises", res.user.email), {
        password: promptt,
      });
    }
  };

  if (user?.uid !== process.env.REACT_APP_ADMIN && user?.type !== "staff")
    return <Navigate to="/login" />;

  return (
    <div>
      <Navbar />
      <section className="TableList UsersList mt-5">
        <p className="p-2 mb-2 fw-bold">All Registered Franchises</p>
        <Table responsive>
          <thead>
            <tr>
              <th>Sno. #</th>
              <th>Full name</th>
              <th>Gender</th>
              <th>Phone number</th>
              <th>Email</th>
              <th>Code</th>
              <th>Date of birth</th>
              <th>Action</th>
              <th>Member since</th>
            </tr>
          </thead>
          {loading && (
            <p
              style={{
                position: "absolute",
                zIndex: 2000,
                left: "47%",
                fontSize: "1.1rem",
                marginTop: "10px",
              }}
            >
              Loading
            </p>
          )}
          <tbody>
            {data.map((element, i) => {
              return (
                <tr key={element.email}>
                  <td>{i + 1}.</td>
                  <td>{element.name}</td>
                  <td>{element.gender}</td>
                  <td>{element.number}</td>
                  <td>{element.email}</td>
                  <td>{element.number?.replace(/\D/g, "").slice(-10)}</td>
                  <td>{element.dob}</td>
                  <th>
                    {user.type !== "staff" && (
                      <button
                        className="btn btn-success mt-1 px-2 py-1 me-2"
                        onClick={() => EditPassword(element)}
                      >
                        Edit password
                      </button>
                    )}
                    <button
                      className="btn btn-primary mt-1 px-2 py-1 me-2"
                      onClick={() =>
                        navigate(
                          `/clients?code=${element.number
                            ?.replace(/\D/g, "")
                            .slice(-10)}&email=${element.email}`
                        )
                      }
                    >
                      View Clients
                    </button>
                    {user.type !== "staff" && (
                      <button
                        className="btn btn-danger mt-1 px-2 py-1 me-2"
                        onClick={() => DeleteFranchise(element)}
                      >
                        Delete
                      </button>
                    )}
                  </th>
                  <td>{element.timeStamp?.toDate().toLocaleDateString()}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default Franchises;
