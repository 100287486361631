import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "./context/AuthContext";
import banner from "./Punch Line Brain Vita.jpg";
import logo from "./Logo n.jpeg";

const FranchiseLogin = () => {
  const { user, LogIn, dispatch } = useAuthContext();
  const [franchise, setFranchise] = useState({});
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFranchise((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const LoginFranchise = async () => {
    if (Object.keys(franchise).length === 2) {
      try {
        const res = await LogIn(franchise.email, franchise.password);
        dispatch({ type: "LOGIN", payload: res.user });
        let objectString = JSON.stringify(res.user);
        document.cookie = "user=" + objectString;
        navigate("/?type=franchise");
      } catch (error) {
        console.log(error);
        setMessage("Something went wrong!");
      }
    } else {
      alert("All fields are required!");
    }
  };

  if (user) return <Navigate to={"/"} />;

  return (
    <section
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <nav className="loginNav">
      <a href="/">
          <img src={logo} style={{ width: "50px", borderRadius: "2px" }} />
        </a>
      </nav>
      {message.length > 0 && (
        <Alert
          className="alert-danger"
          style={{ position: "absolute", top: "0", width: "100%" }}
        >
          {message}
        </Alert>
      )}
      <div className="login-form w-50">
        <h1 className="fw-bold text-center">
          Login to your franchise account!
        </h1>
        <div className="mt-3">
          <label className="fw-bold mt-1">Email</label>
          <input
            type={"email"}
            placeholder="Email"
            className="form-control"
            id="email"
            value={franchise.email}
            onChange={(e) => handleChange(e)}
          />

          <label className="fw-bold mt-1">Password</label>
          <input
            type={"password"}
            placeholder="Pasword"
            className="form-control"
            id="password"
            value={franchise.password}
            onChange={(e) => handleChange(e)}
          />
          <button className="btn btn-primary mt-2" onClick={LoginFranchise}>
            Login
          </button>

          <p className="text-center mt-2">
            New Franchise? <Link to="/franchise">Request here!</Link>
          </p>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <a
            href="https://play.google.com/store/apps/details?id=com.brainvita.dmit"
            target={"_blank"}
            className="btn btn-warning"
          >
            Download App Now!
          </a>
        </div>
      </div>
    </section>
  );
};

export default FranchiseLogin;
