import {
  createContext,
  useState,
  useEffect,
  useContext,
  useReducer,
} from "react";
import { auth } from "../firebase";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import Spinner from "../Spinner.gif";
import Cookies from "js-cookie";
import AuthReducer from "./AuthReducer";

const INITIAL_STATE = {
  currentUser: Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null,
};

const AuthContext = createContext(INITIAL_STATE);

export default function AuthContextProvider({ children }) {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
  const [loading, setLoading] = useState(false);

  function LogIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  useEffect(() => {
    document.cookie =
      "user=" + JSON.stringify(state.currentUser) + "" + "; path=/";
  }, [state.currentUser]);

  if (loading)
    return (
      <img
        src={Spinner}
        style={{ position: "absolute", top: "45%", left: "45%" }}
      />
    );

  return (
    <AuthContext.Provider value={{ user: state.currentUser, LogIn, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
