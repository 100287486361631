import React from "react";

const Footer = () => {
  return (
    <div className="Footer">
      <div>
        <a
          href="https://play.google.com/store/apps/details?id=com.brainvita.dmit"
          className="btn btn-primary"
          target={"_blank"}
          role={"button"}
        >
          Download App
        </a>
      </div>
    </div>
  );
};

export default Footer;
