import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import banner from "../Punch Line Brain Vita.jpg";
import logo from "../Logo n.jpeg";

const Login = () => {
  const navigate = useNavigate();
  const { LogIn, user, dispatch } = useAuthContext();
  const [credentials, setCredentials] = useState({
    email: undefined,
    password: undefined,
  });

  const login = async () => {
    try {
      const res = await LogIn(credentials.email, credentials.password);
      if (res.user.uid === process.env.REACT_APP_ADMIN) {
        dispatch({ type: "LOGIN", payload: res.user });
        let objectString = JSON.stringify(res.user);
        document.cookie = "user=" + objectString;
        navigate("/");
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (user) return <Navigate to={"/"} />;

  return (
    <section
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <nav className="loginNav">
        <a href="/">
          <img src={logo} style={{ width: "50px", borderRadius: "2px" }} />
        </a>
      </nav>
      <div className="login-form w-50">
        <h1 className="fw-bold text-center">Welcome Back!</h1>
        <div>
          <label>Email</label>
          <input
            type={"email"}
            placeholder="Email"
            className="form-control"
            onChange={(e) =>
              setCredentials((prev) => ({ ...prev, email: e.target.value }))
            }
          />
        </div>
        <div className="mt-2">
          <label>Password</label>
          <input
            type={"password"}
            placeholder="Password"
            className="form-control"
            onChange={(e) =>
              setCredentials((prev) => ({ ...prev, password: e.target.value }))
            }
          />
        </div>
        <button className="btn btn-primary mt-2" onClick={login}>
          Login
        </button>
        <div className="d-flex justify-content-center mt-2">
          <a
            href="https://play.google.com/store/apps/details?id=com.brainvita.dmit"
            target={"_blank"}
            className="btn btn-warning"
          >
            Download App Now!
          </a>
        </div>
      </div>
    </section>
  );
};

export default Login;
