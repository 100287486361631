import { collection, doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { GoUnverified, GoVerified } from "react-icons/go";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useAuthContext } from "../context/AuthContext";
import { db } from "../firebase";

const Clients = () => {
  const [data, setData] = useState([]);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const { user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.email !== "veersec.com@gmail.com" && user.email) {
      const reff = doc(db, "Franchises", user.email);
      onSnapshot(reff, (elem) => {
        if (elem.data()) {
          setLoading(true);
          const refClients = collection(db, "users");
          onSnapshot(refClients, (clients) => {
            let list = [];
            clients.docs.forEach((client) => {
              list.push(client.data());
            });
            const filterList = list.filter((e) => {
              return (
                e.Franchise === decodeURIComponent(searchParams.get("code"))
              );
            });
            setData(filterList);
            setLoading(false);
          });
        }
      });
    } else {
      const reff = doc(db, "Franchises", searchParams.get("email"));
      onSnapshot(reff, (elem) => {
        if (elem.data()) {
          setLoading(true);
          const refClients = collection(db, "users");
          onSnapshot(refClients, (clients) => {
            let list = [];
            clients.docs.forEach((client) => {
              list.push(client.data());
            });

            const filterList = list.filter((e) => {
              return (
                e.Franchise.replace(/\D/g, "").slice(-10) ===
                searchParams.get("code").replace(/\D/g, "").slice(-10)
              );
            });
            setData(filterList);
            setLoading(false);
          });
        }
      });
    }
  }, []);

  return (
    <div>
      <Navbar />
      <section className="TableList UsersList mt-5">
        <p className="p-2 mb-2 fw-bold">My Franchise Clients</p>
        <Table responsive>
          <thead>
            <tr>
              <th>Sno. #</th>
              <th>Full name</th>
              <th>Phone number</th>
              <th>Email</th>
              <th>Franchise</th>
              <th>Place of birth</th>
              <th>Date of birth</th>
              <th>Download report</th>
              <th>Member since</th>
              <th>Action</th>
            </tr>
          </thead>
          {loading && (
            <p
              style={{
                position: "absolute",
                zIndex: 2000,
                left: "47%",
                fontSize: "1.1rem",
                marginTop: "10px",
              }}
            >
              Loading
            </p>
          )}
          <tbody>
            {data.map((element, i) => {
              return (
                <tr id={element.email}>
                  <td>{i + 1}.</td>
                  <td>{element["Full Name"]}</td>
                  <td>{element["Phone Number"]}</td>
                  <td>{element["Email"]}</td>
                  <td>{element["Franchise"]}</td>
                  <td>{element["Place of Birth"]}</td>
                  <td>{element["Date of Birth"]}</td>
                  <td className="text-center">
                    {element["report"] ? (
                      <a href={element["report"]} download>
                        <GoVerified
                          size={30}
                          color="blue"
                          className="d-report"
                        />
                      </a>
                    ) : (
                      <GoUnverified size={30} />
                    )}
                  </td>
                  <td>
                    {element["Registered On"]?.split("-")[0] +
                      "-" +
                      element["Registered On"]?.split("-")[1] +
                      "-" +
                      element["Registered On"]?.split("-")[2]}
                  </td>
                  <th>
                    <button
                      className="btn btn-primary mt-1 px-2 py-1"
                      onClick={() =>
                        navigate(`/userdetails/${element["Phone Number"]}`)
                      }
                    >
                      View
                    </button>
                  </th>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default Clients;
