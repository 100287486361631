import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  doc,
  setDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { GoVerified, GoUnverified } from "react-icons/go";
import { GrFormClose } from "react-icons/gr";
import { useAuthContext } from "../context/AuthContext";
import { signInWithEmailAndPassword } from "firebase/auth";

const UsersList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editInputs, setInputs] = useState({});

  const { user } = useAuthContext();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const reff = collection(db, "users");
    const q = query(reff, orderBy("Registered On", "desc"));

    onSnapshot(q, (elem) => {
      setLoading(true);
      let list = [];
      elem.docs.forEach(async (user) => {
        list.push(user.data());
      });
      setData(list);
      setLoading(false);
    });
  }, []);

  const HandleEdit = async (elem) => {
    setShow(true);
    setInputs(elem);
  };

  const HandleDelete = async (elem) => {
    const text = "Are you sure?";

    if (window.confirm(text) == true) {
      const password = elem.Password;
      if (password.length >= 6) {
        const res = await signInWithEmailAndPassword(
          auth,
          elem.Email,
          password
        );
        await deleteDoc(doc(db, "users", elem["Phone Number"]));
        await res.user.delete();
      } else {
        await deleteDoc(doc(db, "users", elem["Phone Number"]));
      }
    }
  };

  return (
    <>
      <section className="TableList UsersList mt-5">
        <p className="p-2 mb-2 fw-bold">All Registered Users</p>
        <Table responsive>
          <thead>
            <tr>
              <th>Sno. #</th>
              <th>Report</th>
              <th>Full name</th>
              <th>Referral code</th>
              <th>Paid Client</th>
              <th className="franchise-col">Franchise</th>
              <th>Action</th>
              <th>Phone number</th>
              <th>Email</th>
              <th>Place of birth</th>
              <th>Date of birth</th>
              <th>Time of birth</th>
            </tr>
          </thead>
          {loading && (
            <p
              style={{
                position: "absolute",
                zIndex: 2000,
                left: "47%",
                fontSize: "1.1rem",
                marginTop: "10px",
              }}
            >
              Loading
            </p>
          )}
          <tbody>
            {data.map((element, i) => {
              return (
                <tr key={new Date() * Math.random() * 100}>
                  <td>{i + 1}.</td>
                  <td className="text-center">
                    {element["report"] ? (
                      <GoVerified size={30} color="blue" />
                    ) : (
                      <GoUnverified size={30} />
                    )}
                  </td>
                  <td>{element["Full Name"]}</td>
                  <td>{element["ReferralCode"]}</td>
                  <td>{element["ReferralCount"]}</td>
                  <td className="franchise-col">{element["Franchise"]}</td>
                  <th>
                    <button
                      className="btn btn-primary mt-1 px-2 py-1"
                      onClick={() =>
                        navigate(`/userdetails/${element["Phone Number"]}`)
                      }
                    >
                      View
                    </button>
                    {user.type !== "staff" && (
                      <>
                        <button
                          className="btn btn-success mt-1 px-2 py-1 ms-2"
                          onClick={() => HandleEdit(element)}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-danger mt-1 px-2 py-1 ms-2"
                          onClick={() => HandleDelete(element)}
                        >
                          Delete
                        </button>
                      </>
                    )}
                    {show && <Model element={editInputs} setShow={setShow} />}
                  </th>
                  <td>{element["Phone Number"]}</td>
                  <td>{element["Email"]}</td>
                  <td>{element["Place of Birth"]}</td>
                  <td>{element["Date of Birth"]}</td>
                  <td>{element["Time of Birth"]}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>
    </>
  );
};

export default UsersList;

const Model = ({ element, setShow }) => {
  const [editInputs, setInputs] = useState({});

  const HandleEditSubmit = async () => {
    const reff = doc(db, "users", editInputs["Phone Number"]);
    setShow(false);
    await setDoc(reff, {
      ...editInputs,
    });
  };

  const handleChange = (e) => {
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  useEffect(() => {
    setInputs(element);
  }, []);

  return (
    <div className="Modal-bg">
      <div className="Modal-content">
        <div className="modal-header py-2">
          <p className="fs-5">Edit user</p>
          <GrFormClose
            onClick={() => setShow(false)}
            size={30}
            cursor="pointer"
          />
        </div>
        <div>
          {Object.keys(editInputs).length > 0 && (
            <>
              <div className="mt-2">
                <label>Full Name</label>
                <input
                  key={"Full Name"}
                  type={"text"}
                  id="Full Name"
                  placeholder={"Full Name"}
                  value={editInputs["Full Name"]}
                  className="form-control"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="mt-1">
                <label>Education</label>
                <input
                  key={"Education"}
                  type={"text"}
                  placeholder={"Education"}
                  value={editInputs["Education"]}
                  className="form-control"
                  id="Education"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="mt-1">
                <label>Franchise code</label>
                <input
                  key={"code"}
                  type={"text"}
                  placeholder={"Franchise code"}
                  id="Franchise"
                  value={editInputs["Franchise"]}
                  className="form-control"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <button
                className="btn btn-primary mt-2"
                onClick={HandleEditSubmit}
              >
                Submit
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
