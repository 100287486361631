import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCzlIyrNoCQCLE6NK4tUW9elk_kIIBQUqw",
  authDomain: "dmit-f7423.firebaseapp.com",
  projectId: "dmit-f7423",
  storageBucket: "dmit-f7423.appspot.com",
  messagingSenderId: "442237953306",
  appId: "1:442237953306:web:99dce9806842319975b8a3",
  measurementId: "G-HWBG7QCC7C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
export { db, auth, storage };
