import React from "react";

const Box = (props) => {
  return (
    <div className={`dash-card ${props.bg}`}>
      <div
        className="d-flex justify-content-between 
      align-items-center p-2 "
      >
        <div>
          <h2 className="bold">{props.user}</h2>
          <p className="fs-5">{props.userType}</p>
        </div>
        <div>
         {window.matchMedia("(min-width: 700px)").matches && 
         props.userImg}
        </div>
      </div>
    </div>
  );
};

export default Box;
