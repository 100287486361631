import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import ClientLogin from "./components/ClientLogin";
import UserDetails from "./components/UserDetails";
import AuthContextProvider from "./context/AuthContext";
import FranchiseLogin from "./FranchiseLogin";
import Clients from "./pages/Clients";
import FranchiseSignup from "./pages/FranchiseRequest";
import Franchises from "./pages/Franchises";
import FRequests from "./pages/FRequests";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Staffform from "./components/Staffform";
import StaffLogin from "./components/StaffLogin";
import StaffList from "./components/StaffList";

function App() {
  return (
    <AuthContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route exact path="/userDetails/:id" element={<UserDetails />} />
          <Route exact path="/admin" element={<Login />} />
          <Route exact path="/login" element={<ClientLogin />} />
          <Route exact path="/franchise" element={<FranchiseSignup />} />
          <Route exact path="/franchise-login" element={<FranchiseLogin />} />
          <Route exact path="/franchise-request" element={<FRequests />} />
          <Route exact path="/franchises" element={<Franchises />} />
          <Route exact path="/clients" element={<Clients />} />
          <Route exact path="/addstaff" element={<Staffform />} />
          <Route exact path="/staff" element={<StaffLogin />} />
          <Route exact path="/staffList" element={<StaffList />} />
        </Routes>
      </Router>
    </AuthContextProvider>
  );
}

export default App;
